import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";

const FORM_STATE = {
  name: null,
  rate: null,
  c_gst: 0,
  s_gst: 0,
  deliverables: null,
  _method: 'post',
};

const DEFAULT_FILTER_STATE = {
  name: null,
  rate: null,
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Name",
    sortable: false,
    value: 'name',
  },
  {
    text: "Rate",
    sortable: false,
    value: 'rate',
  },
  {
    text: "SGST",
    sortable: false,
    value: 's_gst',
  },
  {
    text: "CGST",
    sortable: false,
    value: 'c_gst',
  },
  {
    text: "Action",
    value: "action",
    width: 180,
  },

];


export default {
  data() {
    return {
      operationTitle: 'Decors',
      formFields: { ...FORM_STATE },
      formErrors: new Error({}),
      filters: { ...DEFAULT_FILTER_STATE },
      listUrl: '/decors',
      columns: COLUMN_DEFINATION(this),
      editorDefaultConfig: {
        extraPlugins: ['colorbutton,justify'],
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "clipboard", groups: ["clipboard", "undo"] },
          {
            name: "editing",
            groups: ["find", "selection", "spellchecker", "editing"]
          },
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
          },
          { name: "links", groups: ["links"] },
          { name: "styles", groups: ["styles"] },
          { name: 'colors' },
          { name: "tools", groups: ["tools"] },
          { name: "others", groups: ["others"] },
        ]
      },
      file: {},
      decor_images: [],
    };
  },
  methods: {
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      try {
        const response = await request({
          url: this.formFields.id ? 'decors/update' : 'decors/create',
          method: 'post',
          data: this.formFields,
        });
        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.handleOperationClose();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/decors/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/decors/detail/${ id }`,
        });
        const { data } = response.data;
        this.formFields = {
          ...data,
          property_id: data.property ? data.property.id : null
        };

        this.decor_images = data.decor_images;
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...FORM_STATE };
      }
    },
    handleResetFilterClick() {
      this.filters = { ...DEFAULT_FILTER_STATE };
      this.isFilterApplied = 'reset';
      this.loadList(this.listQueryParams);
    },
    async onChangeFile(e) {
      this.file = await this.uploadFile(e.target.files[0]);
    },
    async uploadFile(file) {
      let formData = new FormData();
      formData.append('file', file);

      try {
        const response = await request({
          url: '/upload',
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        const { data } = response.data;
        return data;

      } catch (error) {
      }
    },
    async openImageUploadModel(id) {
      this.decor_images = [];
      this.formFields.id = id;
      await this.handleEditOperation(id);
      this.$bvModal.show('upload-image');
    },
    async removeImage(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            url: '/decors/delete/file',
            method: 'post',
            data: { id: id },
          }).then((response) => {
            this.handleEditOperation(this.formFields.id);
          });
        } catch (e) {

        }
      }
    },
    async handleSubmitFile() {
      try {
        const response = await request({
          url: '/decors/file',
          method: 'post',
          data: { id: this.formFields.id, decor_images: this.file },
        }).then((response) => {
          const { data } = response.data;
          this.decor_images.push(this.file);
          this.file = {};
        });
      } catch (e) {

      }
    },
    handleOperationClose() {
      this.formFields = { ...FORM_STATE };
      this.$router.push({ name: "decorList" });
    },
  },
  computed: {
    getFile() {
      return this.file;
    }
  }
};
